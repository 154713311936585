<template>
  <section class="gardenManagementPage">
    <SearchForm class="dis-flex flex-x-start flex-y-start">
      <div class="dis-flex flex-x-start flex-y-start">
        <el-form
          ref="ref_searchForm"
          :inline="true"
          :model="searchForm"
          label-width="80px"
        >
          <el-form-item>
            <el-input
              v-model.trim="searchForm.integralstart"
              placeholder="会员积分大于"
            />
          </el-form-item>
          <el-form-item>
            <el-input v-model.trim="searchForm.integralend" placeholder="会员积分小于" />
          </el-form-item>
          <el-form-item>
            <el-input v-model.trim="searchForm.username" placeholder="微信昵称" />
          </el-form-item>
          <el-form-item>
            <el-input v-model.trim="searchForm.wechatId" placeholder="微信号" />
          </el-form-item>
          <el-form-item>
            <el-input v-model.trim="searchForm.id" placeholder="会员ID号" />
          </el-form-item>
          <el-form-item>
            <el-input v-model.trim="searchForm.binding" placeholder="手机号码" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="querycshopmallUserPage">搜 索</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="clearSearch">清 除</el-button>
          </el-form-item>
        </el-form>
      </div>
    </SearchForm>

    <TableContainer title="">
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        border
        :header-cell-style="tabHeader"
        :cell-style="{ textAlign: 'center' }"
      >
        <el-table-column prop="id" label="ID" min-width="40" />
        <el-table-column prop="avatarUrl" label="头像" min-width="60">
          <template slot-scope="scope">
            <div>
              <img :src="scope.row.avatarUrl" width="60" height="60" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="nickname" label="昵称" min-width="100">
          <template slot-scope="scope">
            <div>
              <p>{{ scope.row.nickname }}</p>
              <p>{{ scope.row.username }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="wechatId" label="微信号" min-width="60" />
        <el-table-column prop="binding" label="绑定手机号" min-width="60" />
        <el-table-column prop="contactWay" label="联系方式" min-width="60" />
        <el-table-column prop="comments" label="备注" min-width="60" />
        <el-table-column prop="daytime" label="加入时间" min-width="60" />
        <el-table-column prop="ordernum" label="订单数" min-width="60" />
        <el-table-column prop="integral" label="当前积分" min-width="60" />
        <el-table-column width="300" label="操作">
          <template slot-scope="scope">
            <el-button type="text" class="textOrange" @click="editorClick(scope.row)"
              >编辑</el-button
            >
            <el-button type="text" class="textOrange" @click="detailClick(scope.row)"
              >积分明细</el-button
            >
            <el-button type="text" class="textOrange" @click="scoreClick(scope.row)"
              >充值积分</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <GPagination
        v-show="total > 0"
        ref="ref_GPagination"
        :total="total"
        :page.sync="searchForm.page"
        :size.sync="searchForm.size"
        @pagination="querycshopmallUserPage"
        :page-sizes="pageSizes"
      />
    </TableContainer>

    <!-- 会员编辑s -->
    <GDialog
      :dialog.sync="themeDialog"
      @btnSure="editorSubmitThemeForm()"
      @btnClose="editoResetThemeForm()"
      width="35%"
    >
      <el-form ref="ref_themeForm" label-width="100px" @submit.native.prevent>
        <el-form-item label="会员" label-width="100px">
          <div style="width: 400px" class="dis-flex flex-x-start flex-y-center">
            <img :src="editorRow.avatarUrl" width="60" height="60" />
            <span class="m-left25"
              >{{ editorRow.nickname }} {{ editorRow.username }}</span
            >
          </div>
        </el-form-item>
        <el-form-item label="注册时间" label-width="100px">
          <div style="width: 220px">{{ editorRow.daytime }}</div>
        </el-form-item>
        <el-form-item label="联系方式" label-width="100px">
          <div style="width: 220px">
            <el-input
              v-model.trim="editorRow.contactWay"
              placeholder="请输入联系方式"
              @input="inputPrice"
              type="number"
            />
          </div>
        </el-form-item>
        <el-form-item label="备注" label-width="100px">
          <div style="width: 220px">
            <el-input
              v-model.trim="editorRow.comments"
              placeholder="请输入备注"
              type="text"
            />
          </div>
        </el-form-item>
      </el-form>
    </GDialog>
    <!-- 会员编辑e -->

    <!-- 充值积分s -->
    <GDialog
      :dialog.sync="scoreDialog"
      @btnSure="scoreSubmitThemeForm()"
      @btnClose="resetThemeForm()"
      width="20%"
    >
      <el-radio-group v-model="radio" @change="handleRadioChange">
        <el-radio label="0">充值</el-radio>
        <el-radio label="1">扣除</el-radio>
      </el-radio-group>
      <el-form ref="ref_themeForm" @submit.native.prevent class="m-top15">
        <el-form-item>
          <el-input
            v-model.trim="integral"
            :placeholder="radio == '1' ? '请输入充值积分' : '请输入扣除积分'"
            @input="inputPrice"
            type="number"
          />
        </el-form-item>
      </el-form>
    </GDialog>
    <!-- 充值积分e -->

    <!-- 积分明细s -->
    <GDialog
      :dialog.sync="detailDialog"
      @btnSure="detailSubmitThemeForm()"
      @btnClose="resetThemeForm()"
      width="70%"
    >
      <TableContainer title="">
        <el-table
          :data="detailData"
          stripe
          style="width: 100%"
          border
          :header-cell-style="tabHeader"
          :cell-style="{ textAlign: 'center' }"
        >
          <el-table-column prop="id" label="序号" min-width="40" />
          <el-table-column prop="userId" label="用户ID号" min-width="40" />
          <el-table-column prop="integral" label="赠送或扣除积分数" min-width="80" />
          <el-table-column prop="daytime" label="赠送或扣除积分时间" min-width="80" />
          <el-table-column prop="typename" label="赠送或扣除原因	" min-width="80" />
          <el-table-column prop="notename" label="对应订单id号" min-width="80" />
        </el-table>
      </TableContainer>
    </GDialog>
    <!-- 积分明细e -->
  </section>
</template>

<script>
import { parseTime } from "@utils/filters";
import {
  querycshopmallUserPage,
  updatecshopmallUser,
  updatecshopmallIntegral,
  querycshopmallRegister,
} from "@api/bx/api_contentManagement";
import uploadPics from "@/components/Upload/uploadPics.vue";

export default {
  components: {
    uploadPics,
  },
  data() {
    return {
      themeDialog: {
        title: "会员编辑",
        visible: false,
      },
      scoreDialog: {
        title: "积分操作",
        visible: false,
      },
      detailDialog: {
        title: "积分明细",
        visible: false,
      },
      searchForm: {
        page: 1,
        size: 15,
        username: "",
        integralstart: "",
        integralend: "",
        binding: "",
        wechatId: "",
        id: "",
      },
      editorRow: {},
      total: 0,
      tableData: [],
      detailData: [],
      pageSizes: [15, 30, 45, 60, 75],
      editor: false,
      index: 0,
      parmas: {
        overday: "",
        productcode: "",
        productid: "",
        productimg: "",
        productintegral: "",
        productname: "",
        productprice: "",
        productunit: "",
        upanddown: "0",
        num: "",
      },
      radio: '0',
      scoreRow:{},
      integral:''
    };
  },
  created() {
    // this.queryjfProductclassPage();
    // this.currentTime = parseTime(new Date(), "{y}-{m}-{d}");
    this.querycshopmallUserPage();
  },
  methods: {
    tabHeader() {
      return "backgroundColor:rgba(88, 227, 232, 0.12);color:#000;font-weight:bold;font-size:15px;text-align: center;";
    },
    clearSearch() {
      this.searchForm.page = 1;
      this.searchForm.size = 15;
      this.searchForm.username = "";
      this.searchForm.integralstart = "";
      this.searchForm.integralend = "";
      this.searchForm.binding = "";
      this.searchForm.wechatId = "";
      this.searchForm.id = "";
      this.querycshopmallUserPage();
    },

    // 列表
    querycshopmallUserPage() {
      querycshopmallUserPage(this.searchForm).then((res) => {
        this.tableData = res.records;
        this.total = res.total;
      });
    },

    inputPrice() {
      // this.parmas.productintegral =this.parmas.productprice * this.typeList[this.index].coefficient;
      if (this.parmas.productprice !== "") {
        productintegralSum({
          productid: this.parmas.productid,
          productprice: this.parmas.productprice,
        }).then((res) => {
          this.parmas.productintegral = res.productintegral;
        });
      } else {
        this.parmas.productintegral = "";
      }
    },
    //添加
    add() {
      this.clearData();
      this.editor = false;
      this.queryjfProductclassPage();
      this.themeDialog.visible = true;
    },
    //确定
    submitThemeForm() {
      if (this.parmas.productname == "") {
        this.$alert("请输入产品标题", "提示", {
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      if (this.parmas.productcode == "") {
        this.$alert("请输入产品规格", "提示", {
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      if (this.parmas.productprice == "") {
        this.$alert("请输入产品价格", "提示", {
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      if (this.parmas.num == "" || this.parmas.num == null) {
        this.$alert("请输入产品库存", "提示", {
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      if (this.parmas.overday == "") {
        this.$alert("请选择产品过期时间", "提示", {
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      if (this.parmas.productimg == "") {
        this.$alert("请上传产品图片", "提示", {
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      if (this.editor) {
        updatejfProduct(this.parmas).then((res) => {
          this.themeDialog.visible = false;
          this.clearData();
          this.querycshopmallUserPage();
        });
      } else {
        addjfProduct(this.parmas).then((res) => {
          this.themeDialog.visible = false;
          this.clearData();
          this.querycshopmallUserPage();
        });
      }
    },
    //取消按钮
    resetThemeForm() {
      this.themeDialog.visible = false;
      this.scoreDialog.visible = false;
      this.detailDialog.visible = false;
      this.radio='0'
      this.integral=''
      // this.querycshopmallUserPage();
    },
    //编辑
    editorClick(row) {
      console.log(row);
      this.editorRow = row;
      this.themeDialog.visible = true;
    },

    editorSubmitThemeForm() {
      updatecshopmallUser({
        comments: this.editorRow.comments,
        contactWay: this.editorRow.contactWay,
        id: this.editorRow.id,
      }).then((res) => {
        this.themeDialog.visible = false;
        this.querycshopmallUserPage();
      });
    },
    editoResetThemeForm(){
      this.themeDialog.visible = false;
    },
    //积分明细
    detailClick(row) {
      querycshopmallRegister({
        userId: row.id,
      }).then((res) => {
        this.detailData = res;
        if(this.detailData.length==0){
          this.$alert(row.nickname + row.username +"无积分明细数据", "提示", {
          dangerouslyUseHTMLString: true,
        });
        }else{
          this.detailDialog.visible = true;
        }
       
      });
    },

    //积分充值 扣除
    scoreSubmitThemeForm() {
      if(this.integral<=0||this.integral==''){
        this.$alert("请输入正确的积分", "提示", {
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      updatecshopmallIntegral({
        id: this.scoreRow.id,
        integral: this.integral,
        isrecharge: this.radio,
      }).then((res) => {
        this.scoreDialog.visible = false;
        this.radio = "0";
        this.integral=''
        this.searchForm.page=this.searchForm.page
        this.searchForm.size=15
        this.querycshopmallUserPage();
      });
    },

    detailSubmitThemeForm() {
      this.detailDialog.visible = false;
    },
    handleRadioChange(value) {

      if(this.radio=='1'){
        this.integral=this.scoreRow.integral
      }else{
        this.integral=''
      }
    },
    //充值积分
    scoreClick(row) {
      this.scoreRow=row
      this.scoreDialog.visible = true;
    },
  },
};
</script>

<style scoped lang="scss">
/* .tree /deep/ .el-tree-node__content{
		padding-left: 40px
	} */
.red {
  color: red;
}
.turnBule {
  color: #333;
  background-color: #58e3e8;
  border-color: rgba(56, 185, 190, 0.12);
  margin-left: 15px;
}

.turnBule:hover,
.turnBule:focus {
  background: rgba(56, 185, 190, 0.5);
  border-color: rgba(56, 185, 190, 0.5);
  color: #fff;
}

.el-button--text.reviewed {
  color: #38b9be;
}

.reviewed {
  color: #38b9be;
}

.el-tree-node__content {
  z-index: 999;
  padding-left: 40px;
}

.directory-in {
  width: 32%;
  line-height: 60px;
  background: #4095e5;
}
</style>
